import * as React from 'react';
import { Button, Flex } from 'antd';
import { Facebook, LinkedIn, Twitter } from '@mui/icons-material';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useResizeWidthWindow } from '../hooks/useResizeWidthWindow';
import { colors } from '../common/constStyles';
import { paths } from '../common';

const DivStyled = styled.div`
  padding: 20px;
  background-color: ${colors.colorSecond};

  && .ant-btn-link{
    color: ${colors.colorTextDark}
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const MainFooter: React.FC = () => (
    <DivStyled>
        <Flex justify={'space-between'} vertical={useResizeWidthWindow() < 800} gap={20} align={useResizeWidthWindow() < 800 ? 'stretch' : 'center'}>
            <Flex justify={'center'} gap={5}>
                <span>© Copyright 2024 ServiceIn.</span>
                <span>All Rights Reserved</span>
            </Flex>
            <Flex justify={'space-between'} vertical={useResizeWidthWindow() < 400 } gap={10} align={useResizeWidthWindow() < 400 ? 'stretch' : 'center'}>
                <Flex justify={'space-around'} gap={10}>
                    <span>
                        <Link to={paths.POLICY} aria-label={'Privacy policy'}>
                                Privacy policy
                        </Link>
                    </span>
                    <span> | </span>
                    <Link to={paths.TERMS} aria-label={'Terms and Conditions'}>
                        Terms and Conditions
                    </Link>
                </Flex>
                <Flex gap={10} className={'social_box'} justify={'center'} align={'center'}>
                    <a href="/" target={'_blank'} aria-label={'Twitter'}>
                        <Button type="link" aria-label={'Twitter'}><Twitter/></Button>
                    </a>
                    <a href="https://www.facebook.com/servicein_official" target={'_blank'} aria-label={'Facebook'}>
                        <Button type="link" aria-label={'Facebook'}><Facebook/></Button>
                    </a>
                    <a href='/'>
                        <Button type="link" aria-label={'LinkedIn'}><LinkedIn/></Button>
                    </a>
                </Flex>
            </Flex>
        </Flex>
    </DivStyled>
);
