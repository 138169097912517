import React from 'react';
import { Card, Col, Row } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colors } from '../common/constStyles';

const ColStyle = styled(Col)`
  padding: 10px
`;

const CardStyle = styled(Card)`
  border-radius: 15px;
  border: 0.5px solid ${colors.colorBorder};
  box-shadow: 1px 1px 1px hsla(0,6%,90%,.5);
`;

export const PricePage: React.FC = () => {
  const { t } = useTranslation();

  return (
      <div>
        <p style={{ fontSize: '26px', textAlign: 'center' }}>  {t('Choose the plan that\'s right for you')}</p>
        <section className={'price_plan'}>
          <Row>
            <ColStyle span={8}>
              <CardStyle bordered={true}>
                <p>Card content</p>
              </CardStyle>
            </ColStyle>
            <ColStyle span={8}>
              <CardStyle bordered={true}>
                <p>Card content</p>
              </CardStyle>
            </ColStyle>
            <ColStyle span={8}>
              <CardStyle bordered={true}>
                <p>Card content</p>
              </CardStyle>
            </ColStyle>
          </Row>
        </section>
      </div>
  );
};
