import * as React from 'react';
import { useEffect, useState } from 'react';
import { Flex } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../common/constStyles';
import { AppDispatch, RootState } from '../store';
import { fetchListServiceGlobal } from '../store/slices/servicesSlice';
import { useCategory } from '../hooks/useGategory';

const FlexStyled = styled(Flex)`
  //padding: 40px 40px 10px 40px;
  padding-left: 40px;
  text-align: left;
  margin: 0 auto;
  width: 100%;

  font-size: 14px;
  font-weight: 600;
  color: ${colors.colorTextDark};
  padding-right: 10px;

  @media (max-width: 768px) {
    padding: 20px 20px 5px 20px;
  }
`;

const SpanStyled = styled.span`
  cursor: pointer;

  &:hover {
    color: ${colors.colorViolet};
    text-decoration: underline;
  }
`;

interface IPopularCategory {
  caption: string;
  count: number;
}

export const PopularCategory: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { GROUP_CATEGORY_SERVICES } = useCategory();
  const counterCategory = useSelector<RootState, { [key: string]: number }>((state) => state.services.counterCategory);
  const [popularCategories, setPopularCategories] = useState<IPopularCategory[]>([]);

  const getPopular = () => {
    const popularCategoryArr: any = [];

    Object.keys(GROUP_CATEGORY_SERVICES).forEach((key) => {
      const groupCategoryService = GROUP_CATEGORY_SERVICES[key];
      const count = groupCategoryService.category.map((cat) => counterCategory[cat] || 0).reduce((val1, val2) => val1 + val2);

      const itemPopularCategory = {
        caption: groupCategoryService.caption,
        count,
      };

      popularCategoryArr.push(itemPopularCategory);
    });

    return popularCategoryArr;
  };

  useEffect(() => {
    dispatch(fetchListServiceGlobal('counter=true'));
  }, []);

  useEffect(() => {
    if (Object.keys(counterCategory).length) {
      const popular = getPopular()
        .sort((a: any, b: any) => b.count - a.count)
        .slice(0, 3);
      setPopularCategories(popular);
    }
  }, [counterCategory]);

  const clickPopularSearch = () => {
    console.log('clickPopularSearch');
  };

  return (
        <FlexStyled gap={2}>
            {
                popularCategories.length
                && <>
                    <p>{t('Popular')}:</p>
                    <div>
                        {
                            popularCategories.map((category, index) => (
                                <SpanStyled key={index} onClick={clickPopularSearch}>
                                    {category.caption}{index < popularCategories.length - 1 && ', '}
                                </SpanStyled>
                            ))
                        }
                    </div>
                </>
            }
        </FlexStyled>
  );
};
