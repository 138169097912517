import { Layout } from 'antd';
import React, { ReactNode } from 'react';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import styled from 'styled-components';
import { MainFooter } from '../components/MainFooter';
import { MainHeader } from '../components/MainHeader';
import { colors } from '../common/constStyles';

interface IMainTemplatePage {
  children: ReactNode | string
}

export const HeaderStyled = styled(Header)`
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  position: sticky;
  z-index: 100;
  top: 0px;
  left: auto;
  right: 0px;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none;
  background: rgb(255, 255, 255);
  -webkit-box-pack: center;
  justify-content: center;
  backdrop-filter: blur(4px);
`;

export const ContentStyled = styled(Content)`
  && .text_main{
    font-size: 3rem; // 4 is big
    font-weight: bold;
    padding: 0;
    line-height: 1.2;
    margin: 0;
  }

  && .text_main.regular{
    color: ${colors.colorTextDark};
  }

  && .text_main.accent{
    color: ${colors.colorMain};
  }

  && .text_second{
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0;
    margin: 0;
    line-height: 1.5;
    color: ${colors.colorTextDark};
  }

  @media (max-width: 768px) {
    && .text_main{
      font-size: 2rem;
    }
    && .text_second{
      font-size: 1rem;
    }
  }
    
`;

export const MainTemplatePage: React.FC<IMainTemplatePage> = (props) => (
    <>
        <Layout style={{
          display: 'flex',
          minHeight: '100vh',
          margin: '0 auto',
        }}>
            <HeaderStyled>
                <MainHeader/>
            </HeaderStyled>
            <ContentStyled style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
                <div className="site-layout-content" style={{
                  display: 'flex', flex: '1', overflowY: 'auto', flexDirection: 'column',
                }}>
                    {props.children}
                </div>
            </ContentStyled>
            <Footer>
                <MainFooter />
            </Footer>
        </Layout>
    </>
);
