import React, { useCallback, useEffect } from 'react';
import './styles/App.css';
// import './styles/menu.css';
// import './styles/transfer.css';
// import './styles/datepicker.css';
// import './styles/collapse.css';
// import './styles/button.css';
// import './styles/spin.css';
// import './styles/input.css';
// import './styles/checkbox.css';
// import './styles/select.css';
// import './styles/tabs.css';
// import './styles/table.css';
// import './styles/fileUpload.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
// import { Result } from 'antd';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigProvider } from 'antd';
import { createTheme, ThemeProvider } from '@mui/material';
import { Helmet } from 'react-helmet';

import { useJsApiLoader } from '@react-google-maps/api';
import { LoginPage } from './pages/LoginPage';
import {
  API,
  COOKIE_CLIENT_NEWCAMER_NUMBER,
  COOKIE_ID_TOKEN,
  COOKIE_LANGUAGE,
  decodeToken,
  LANG_EN,
  LIBRARIES,
  paths,
} from './common';
import { SetNewPasswordPage } from './pages/SetNewPasswordPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { NotFoundPage } from './pages/NotFound';
import { MainTemplatePage } from './pages/MainTemplatePage';
import { ConfirmRegistrationPage } from './pages/ConfirmRegistrationPage';
import { UserProfilePage } from './pages/UserProfilePage';
import { SearchPage } from './components/SearchPage';
import { AppDispatch, selectLanguage, selectLogin } from './store';
import { setLanguage, setLogin } from './store/slices/settingsSlice';
import { PricePage } from './pages/PricePage';
import { AboutPage } from './pages/AboutPage';
import { FullCardBusinessPage } from './components/FullCardBusinessPage';
import { ContactUsPage } from './pages/ContactUsPage';
import { fonts } from './common/constStyles';
import { MainPage } from './components/MainPage';
import { RegistrationPage } from './pages/RegistrationPage';
import { CheckStatusRegistration } from './components/CheckStatusRegistration';
import { NewInCanadaPage } from './pages/NewInCanadaPage';
import { CommunityPage } from './pages/CommunityPage';
import { FullCardPostPage } from './components/FullCardPostPage';
import { setterApp } from './store/slices/appSlice';
import { GoTo } from './components/GoTo';
import { PolicyPage } from './pages/PolicyPage';
import { TermsPage } from './pages/TermsPage';

const myTheme = createTheme({
  typography: {
    fontFamily: 'Plus Jakarta Sans, sans-serif',
  },
});

function App() {
  const { i18n } = useTranslation();
  const language = useSelector(selectLanguage);
  const login = useSelector(selectLogin);

  const dispatch = useDispatch<AppDispatch>();

  console.log('app render', login);

  useEffect(() => {
    const clientNewCamerNumber = Cookies.get(COOKIE_CLIENT_NEWCAMER_NUMBER);
    if (!clientNewCamerNumber) {
      Cookies.set(COOKIE_CLIENT_NEWCAMER_NUMBER, uuidv4());
    }
    dispatch(setLanguage(Cookies.get(COOKIE_LANGUAGE) || LANG_EN));

    const token = Cookies.get(COOKIE_ID_TOKEN) || '';
    const decodedToken = decodeToken(token);

    if (decodedToken) {
      dispatch(setLogin(decodedToken.email));
    }
  }, []);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: API.GOOGLE_MAPS as string,
    libraries: LIBRARIES as any[],
  });

  console.log(isLoaded);

  const getDataFromCookies = useCallback((cookieNames: string[]): string => cookieNames.map((cookieName) => `${cookieName}=${Cookies.get(cookieName)}`).join('&'), []);

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      console.error('error interceptors', error);
      if (+error.response.status === 404) {
        dispatch(setterApp({ goto: paths.NOT_FOUND }));
      }
      return Promise.reject(error);
    },
  );

  axios.interceptors.request.use(async (config) => {
    if (!config.url?.includes(COOKIE_CLIENT_NEWCAMER_NUMBER)) {
      const cookiesNames = [COOKIE_CLIENT_NEWCAMER_NUMBER, COOKIE_LANGUAGE];
      let signJoin = '?';
      if (config.url?.includes('?')) {
        signJoin = '&';
      }
      config.url = `${config.url}${signJoin}${getDataFromCookies(cookiesNames)}`;
    }

    if (config.url && config.url.includes(API.URL_LOGIN)
      && !config.url.includes(paths.CHANGE_PASSWORD)) {
      return config;
    }

    const headers = config.headers as any;

    if ((!config.url.includes(paths.LIST_POST) && !config.url.includes(paths.LIST_SERVICES) && !config.url.includes(paths.LIST_QUESTIONS)) || config.url.includes('userId')) {
      headers.Authorization = `Bearer ${Cookies.get(COOKIE_ID_TOKEN)}`;
    }

    if (config.url && config.url.includes(API.URL_REST_API)) {
      headers['x-api-key'] = API.API_KEY;
    }

    return config;
  }, () => {
  });

  useEffect(() => {
    Cookies.set(COOKIE_LANGUAGE, language);
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <>
      <Helmet>
        <title>ServiceIn.ca | Connecting Users and Service Providers | Find Local Service & Professional</title>
        <meta
            name="description"
            content="Connecting Users and Service Providers | Find Local Service & Professional | From small businesses to huge companies - close deals, build relationships, grow their business and find success."
        />
        <link rel="canonical" href='https://servicein.ca' />
      </Helmet>
      <ConfigProvider
          theme={{
            token: {
              fontFamily: fonts.font3,
            },
          }}
      >
        <ThemeProvider theme={myTheme} >
        <Router>
          <GoTo />
          <CheckStatusRegistration />
          <Routes>
            <Route path={paths.LOGIN} element={<MainTemplatePage >
                <LoginPage/>
              </MainTemplatePage>}/>
            <Route path={paths.REGISTRATION_USER} element={<MainTemplatePage >
              <RegistrationPage role={'user'}/>
            </MainTemplatePage>}/>
            <Route path={paths.REGISTRATION_PROVIDER} element={<MainTemplatePage >
              <RegistrationPage role={'provider'}/>
            </MainTemplatePage>}/>
            <Route path={paths.NEW_IN_CANADA} element={<MainTemplatePage>
              <NewInCanadaPage />
            </MainTemplatePage>}/>
            <Route path={paths.COMMUNITY} element={<MainTemplatePage>
              <CommunityPage />
            </MainTemplatePage>}/>
            <Route path={paths.SET_NEW_PASSWORD} element={<SetNewPasswordPage/>}/>
            <Route path={paths.RESET_PASSWORD} element={<ResetPasswordPage/>} />
            <Route path={paths.CONTACT} element={<MainTemplatePage >
              <ContactUsPage />
            </MainTemplatePage>}/>
            <Route path={paths.PRICE} element={<MainTemplatePage >
              <PricePage />
            </MainTemplatePage>}/>
            <Route path={paths.ABOUT} element={<MainTemplatePage >
              <AboutPage />
            </MainTemplatePage>}/>
            <Route path={paths.POLICY} element={<MainTemplatePage >
              <PolicyPage />
            </MainTemplatePage>}/>
            <Route path={paths.TERMS} element={<MainTemplatePage >
              <TermsPage />
            </MainTemplatePage>}/>
            <Route path={`${paths.PUBLIC_SERVICE}/:idAuthor/:id`} element={<MainTemplatePage >
              <FullCardBusinessPage typeLink={'public_service'} />
            </MainTemplatePage>}/>
            <Route path={`${paths.PUBLIC_RESOURCE}/:keyUrl`} element={<MainTemplatePage >
              <FullCardBusinessPage typeLink={'public_resource'} />
            </MainTemplatePage>}/>
            <Route path={`${paths.PUBLIC_POST}/:idAuthor/:id`} element={<MainTemplatePage >
              <FullCardPostPage />
            </MainTemplatePage>}/>
            <Route path={paths.CONFIRM_REGISTRATION} element={<ConfirmRegistrationPage/>}/>
            {
                <Route path={paths.USER_PROFILE} element={<MainTemplatePage >
                  <UserProfilePage/>
                </MainTemplatePage>}/>
            }
            <Route path={paths.SEARCH}
                   element={
              <MainTemplatePage >
                <SearchPage/>
              </MainTemplatePage>
            }/>
            <Route path={paths.MAIN}
                   element={
                     <MainTemplatePage >
                       <MainPage/>
                     </MainTemplatePage>
                   }
            />
            <Route path="*" Component={NotFoundPage}/>
          </Routes>
        </Router>
        </ThemeProvider>
      </ConfigProvider>
    </>
  );
}

export default App;
