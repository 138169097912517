import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { colors } from '../common/constStyles';
import { ButtonRegistration } from '../components/Buttons/ButtonRegistration';
import { ScrollUp } from '../components/ScrollUp';
import { SocialBlock } from '../components/SocialBlock';

const DivStyled = styled.div`
  position: relative;
`;

const RowStyled = styled(Row)`
  padding: 40px;


  .textblock_main {
    padding: 10px 0 0 10px;
    text-align: justify;
  }
  
  .textblock_second {
    padding: 20px 0 0 20px;
    white-space: pre-line;
    text-align: justify
  }
  
  @media (max-width: 768px) {
    padding: 20px;
    
    && .textblock_second,
    && .textblock_main {
      padding: 5px;
    }
  }
`;

const DivImageStyled = styled.div`
  height: 500px;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 200px;
  }
`;

export const AboutPage: React.FC = () => {
  const { t } = useTranslation();

  return <DivStyled>
      <Helmet>
          <title>About us | ServiceIn.ca</title>
          <meta name="description" content="We help people connect with local businesses through online-catalog of local businesses - from small businesses to huge companies" />
          <link rel="canonical" href='https://servicein.ca/about' />
      </Helmet>
      <ScrollUp/>
      <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
          <RowStyled>
              <Col className="gutter-row" xs={18} sm={18} md={18} lg={18} xl={18}
                   style={{ textAlign: 'center', margin: '0 auto 20px' }}>
                  <h1 className={'text_main accent'}>{t('Helping people connect')} </h1>&nbsp;
                  <h1 className={'text_main'}>{t('with local businesses')}</h1>
              </Col>
              <Col className="gutter-row" xs={18} sm={18} md={18} lg={18} xl={18}
                   style={{ textAlign: 'center', margin: '0 auto 20px' }}>
                <h2 className={'text_second'}>
                    {t('From small businesses to huge companies - close deals, build relationships, grow their business and find success.')}
                </h2>
              </Col>
              <Col className="gutter-row" xs={18} sm={18} md={18} lg={18} xl={18}
                   style={{ textAlign: 'center', margin: '0 auto 20px' }}>
                  <ButtonRegistration/>
              </Col>
          </RowStyled>
      </div>
      <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
          <RowStyled>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <DivImageStyled>
                      <img
                          src={'/img_AboutPage.jpg'}
                          alt={'add'}
                          loading="lazy"
                          width={'100%'}
                      />
                  </DivImageStyled>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} style={{ whiteSpace: 'pre-line' }}>
                  <div className={'textblock_main'}>
                      <h1 className={'text_main'}>{t('Our Mission')}</h1>
                  </div>
                  <div className={'textblock_second'}>
                      <h2 className={'text_second'}>{t('We’re all about helping businesses grow and succeed')}. </h2>
                      <h2 className={'text_second'}>{t('Our platform connects businesses with the resources they need to thrive, from business listings and online directories to professional networking opportunities')}. </h2>
                      <h2 className={'text_second'}>{t('We’re passionate about helping businesses of all sizes and types achieve their goals and make a positive impact in their communities')}.</h2>
                  </div>
              </Col>
          </RowStyled>
      </div>
         <Row>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
                <div style={{
                  backgroundColor: colors.colorSecond,
                  padding: '2em',
                  textAlign: 'center',
                  marginTop: '1.5em',
                }}>
                    <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
                        <h1 className={'text_main'}>{t('Get in touch with us ')}</h1>
                        <p style={{ margin: '1.3em 0', fontSize: '1.3em' }}>{t('Need help or Have a question?')}</p>
                        <SocialBlock/>
                    </div>
                </div>
            </Col>
         </Row>
    </DivStyled>;
};
