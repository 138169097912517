import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import {
  Button, Col, Form, Input, notification, Row, Spin,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import styled from 'styled-components';
import axios from 'axios';
import { NotificationFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { colors } from '../common/constStyles';
import { RequestToUsType } from '../../../common/types';
import { API, paths } from '../common';
import { useAlertMessage } from '../hooks/useAlertMessage';

const DivStyled = styled.div`
  margin: 0 auto;
  
`;

const RowStyled = styled(Row)`
  padding: 40px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const PStyled = styled.p`
  padding: 40px 0 0;
  font-size: 1.5rem;
  white-space: pre-line;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const DivFormStyled = styled.div`
  background-color: ${colors.colorSecond}; 
  padding: 2rem;
  
  @media (max-width: 768px) {
    margin-top: 10px;
    padding: 1.3rem;
  }
`;

const Context = React.createContext({ name: 'Default' });

export const ContactUsPage: React.FC = () => {
  const { t } = useTranslation();
  const [spinModalRegistration, setSpinModalRegistration] = useState(false);
  const [msgRegistration, setMsgRegistration] = useState('');
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  const initialValues: RequestToUsType = {
    yourName: '', subject: '', message: '', email: '',
  };

  const openNotification = useCallback((text: string) => {
    api.info({
      message: 'Request process',
      description: <Context.Consumer>{() => `${text}!`}</Context.Consumer>,
      icon: <NotificationFilled style={{ color: '#108ee9' }}/>,
    });

    // notification.open({
    //   message: 'Request process',
    //   description: <div dangerouslySetInnerHTML={{ __html: `${text}` }} />,
    //   icon: <NotificationFilled style={{ color: '#108ee9' }}/>,
    // });
  }, []);

  const onFinish = useCallback(async (values: any) => {
    setSpinModalRegistration(true);
    setMsgRegistration('');
    try {
      const result = (await axios.post(`${API.URL_REST_API}${paths.USER_REQUEST}`, values)).data;

      form.resetFields();

      openNotification(result.message);
    } catch (e: any) {
      setMsgRegistration(e.response.data.message);
    }
    setSpinModalRegistration(false);
  }, []);

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const renderMessageErrorRegistration = useAlertMessage('error', msgRegistration);

  const contextValue = useMemo(() => ({ name: 'Ant Design' }), []);

  return <DivStyled>
      <Helmet>
          <title>Contact Us | ServiceIn.ca</title>
          <meta name="description" content="We are always ready to help you, discuss your request, get additional information, leave feedback, help register your business or help you find a specialist." />
          <link rel="canonical" href='https://servicein.ca/contact' />
      </Helmet>
      <Context.Provider value={contextValue}>
          {contextHolder}
        <RowStyled>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ paddingRight: '10px' }}>
                <div>
                    <h1 className={'text_main'}>{t('Have Questions?')}</h1>
                    <PStyled>
                        {t('Please feel free to use our contact form to get in touch with us.')}
                    </PStyled>
                    <PStyled>
                        {t('We look forward to hearing from you!')}
                    </PStyled>
                </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <DivFormStyled>
                    <Spin spinning={spinModalRegistration} tip={t('Please wait')} style={{ width: '100%' }}>
                    <Form
                        form={form}
                        name="contactUs"
                        style={{ width: '100%' }}
                        initialValues={{ initialValues }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item<RequestToUsType>
                            name="yourName"
                            rules={[{ required: true, message: t('Please, fill this field!') }]}
                        >
                            <Input placeholder={t('Your Name')} size={'large'}/>
                        </Form.Item>
                        <Form.Item<RequestToUsType>
                            name="email"
                            rules={[{ required: true, message: t('Please, fill this field!') },
                              {
                                type: 'email',
                                message: t('The input is not valid E-mail!'),
                              }]}
                        >
                            <Input placeholder={t('Email address')} size={'large'}/>
                        </Form.Item>
                        <Form.Item<RequestToUsType>
                            name="subject"
                            rules={[{ required: true, message: t('Please, fill this field!') }]}
                        >
                            <Input placeholder={t('Subject')} size={'large'}/>
                        </Form.Item> <Form.Item<RequestToUsType>
                        name="message"
                        rules={[{ required: true, message: t('Please, fill this field!') }]}
                    >
                        <TextArea rows={6} placeholder={t('Your Message')} size={'large'} />
                    </Form.Item>
                        <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button type="primary" htmlType="submit" size={'large'} aria-label={'Send Message'}>
                                {t('Send Message')}
                            </Button>
                        </Form.Item>
                        {
                            renderMessageErrorRegistration
                        }
                    </Form>
                    </Spin>
                </DivFormStyled>
            </Col>
        </RowStyled>
      </Context.Provider>
    </DivStyled>;
};
