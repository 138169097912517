import * as React from 'react';
import { Avatar, Flex } from 'antd';
import { EmailOutlined, FacebookOutlined, WhatsApp } from '@mui/icons-material';
import { colors } from '../common/constStyles';

export const SocialBlock: React.FC = () => (
    <Flex gap={20} justify={'center'}>
        <a href="mailto:info@servicein.ca" target={'_blank'} aria-label={'MailTo'}><Avatar size={80} style={{
          backgroundColor: colors.colorMain,
          color: 'white',
        }} icon={<EmailOutlined fontSize={'large'}/>}/></a>
        <a href="https://www.facebook.com/servicein_official" target={'_blank'} aria-label={'Facebook'}><Avatar size={80} style={{
          backgroundColor: colors.colorMain,
          color: 'white',
        }} icon={<FacebookOutlined fontSize={'large'}/>}/></a>
        <a href="https://chat.whatsapp.com/BmezOXd6iyH2fgNp8jq1H4" target={'_blank'} aria-label={'WhatsApp'}><Avatar size={80} style={{
          backgroundColor: colors.colorMain,
          color: 'white',
        }} icon={<WhatsApp fontSize={'large'}/>}/></a>
    </Flex>
);
