import * as React from 'react';
import { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import { PostType } from '../../../common/types';
import { COOKIE_ID_TOKEN, decodeToken } from '../common';
import { AppDispatch, RootState } from '../store';
import { ScrollUp } from './ScrollUp';
import { CardPost } from './Cards/CardPost';
import { fetchPublicPost } from '../store/slices/postsSlice';

export const FullCardPostPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const urlParams = useParams();
  const postPublic = useSelector<RootState, PostType>((state) => state.post.postPublic);
  const postLoader = useSelector<RootState, boolean>((state) => state.post.postLoader);
  const [currentId, setCurrentId] = useState<string>();

  const canonicalUrl = `https://servicein.ca/public_post/${postPublic.userId}/${postPublic.id}`;

  useEffect(() => {
  }, [postPublic]);

  useEffect(() => {
    const newParam = { id: urlParams.id!, idAuthor: urlParams.idAuthor! };
    if (currentId !== urlParams.id) {
      setCurrentId(urlParams.id);
      dispatch(fetchPublicPost(newParam));
    }
  }, [currentId]);

  const token = Cookies.get(COOKIE_ID_TOKEN) || '';
  const decodedToken = decodeToken(token);

  return (
      <Spin spinning={postLoader} tip={'please wait ...'} style={{ minHeight: 400 }}>
        <ScrollUp/>
        <Helmet>
          <title>{`${postPublic.caption} | Review`}</title>
          <meta name="description" content={postPublic.text} />
          <link rel="canonical" href={canonicalUrl} />
        </Helmet>
        {
          <CardPost post={postPublic} decodedToken={decodedToken} />
        }
      </Spin>
  );
};
