import { CountryType, imageSizeType, LanguageType } from '../../../common/types';

export const DEFAULT_VALUE = ' ';

export const DEFAULT_NOT_VALUE = '* not value *';

export const PAGINATION_TABLE = { pageSize: 50, pageSizeOptions: ['20', '50', '100', '1000', '10000'] };

export const COOKIE_CLIENT_NEWCAMER_NUMBER = 'clientNewCamerNumber';
export const COOKIE_LANGUAGE = 'language';
export const COOKIE_ID_TOKEN = 'IdToken';
export const COOKIE_REFRESH_TOKEN = 'RefreshToken';
export const COOKIE_ACCESS_TOKEN = 'AccessToken';
export const COOKIE_SESSION = 'session';
export const COOKIE_START_REFRESH_TOKEN = 'startRefreshToken';
export const COOKIE_REGISTRATION_EMAIL = 'registration_email';
export const COOKIE_REGISTRATION_ROLE = 'registration_role';
export const COOKIE_REGISTRATION_CONFIRMED_EMAIL = 'registration_confirmed_email';
export const COOKIE_REGISTRATION_FIRST_SERVICE = 'registration_first_service';
export const COOKIE_REGISTRATION_FIRST_LOGIN = 'registration_first_login';

export const LANG_UA = 'ua';
export const LANG_EN = 'gb';
export const LANG_VN = 'vn';
export const LANG_FR = 'fr';

export const COUNTRY_CA = 'ca';
export const COUNTRY_VN = 'vn';
export const COUNTRY_UA = 'ua';

export const SUPPORT_LANGUAGE: { [key: string]: string } = {
  [LANG_VN]: 'Vietnamese',
  [LANG_UA]: 'Українська',
  [LANG_EN]: 'English',
};

export const LIST_COUNTRY: { [key: string]: string } = {
  [COUNTRY_CA]: 'Canada',
  [COUNTRY_VN]: 'Vietnam',
  [COUNTRY_UA]: 'Ukraine',
};

export const LANGUAGE_COUNTRY: { [key: string]: CountryType } = {
  [LANG_EN]: COUNTRY_CA,
  [LANG_FR]: COUNTRY_CA,
  [LANG_VN]: COUNTRY_VN,
  [LANG_UA]: COUNTRY_UA,
};

export const COUNTRY_LANGUAGE: { [key: string]: LanguageType[] } = {
  [COUNTRY_CA]: [LANG_EN, LANG_FR],
  [COUNTRY_VN]: [LANG_VN],
  [COUNTRY_UA]: [LANG_UA],
};

export const REST_TIME_LIFE_OF_TOKEN = 100; // seconds

export const paths = {
  USERS: '/users',
  LOGIN: '/login',
  CONTACT: '/contact',
  PRICE: '/price',
  ABOUT: '/about',
  POLICY: '/policy',
  TERMS: '/terms',
  SET_NEW_PASSWORD: '/set_new_password',
  RESET_PASSWORD: '/reset_password',
  CONFIRM_RESET_PASSWORD: '/confirm_reset_password',
  CHANGE_PASSWORD: '/change_password',
  USER_PROFILE: '/user_profile',
  SERVICE: '/service',
  POST: '/post',
  QUESTION: '/question',
  PUBLIC_SERVICE: '/public_service',
  CHECK_COMPANY_NAME: '/check_company_name',
  PUBLIC_RESOURCE: '/public_resource',
  PUBLIC_POST: '/public_post',
  PUBLIC_QUESTION: '/public_question',
  LIST_SERVICES: '/list_services',
  LIST_POST: '/list_posts',
  LIST_QUESTIONS: '/list_questions',
  MESSENGER: '/messenger',
  LIST_MESSENGER: '/list_messenger',
  AUTOCOMPLETE_SEARCH: '/autocomplete_search',
  AUTOCOMPLETE_SEARCH_LOCAL: '/autocomplete_search_local',
  USER_REQUEST: '/user_request',
  ADD_SERVICE_OFFICE: '/add_service_office',
  PROJECTS: '/projects',
  PROJECT: '/project',
  REFRESH_TOKEN: '/refresh_token',
  NOT_FOUND: '/404',
  MAIN: '/',
  SEARCH: '/search',
  REGISTRATION: '/registration',
  REGISTRATION_USER: '/registration_user',
  REGISTRATION_PROVIDER: '/registration_provider',
  CONFIRM_REGISTRATION: '/confirm_registration',
  STATUS_REGISTRATION: '/status_registration',
  ALL_SERVICES: '/all_services',
  NEW_IN_CANADA: '/new_in_canada',
  COMMUNITY: '/blog',
};

export const ROLE_ADMIN = 'admin';
export const ROLE_ADMIN_POOL = 'adminPool';
export const ROLE_USER = 'user';
export const ROLE_PROVIDER = 'provider';
export const PERMISSION_MANAGE_USER = 'Manage users';

export const permissionsList = [
  PERMISSION_MANAGE_USER,
];

export const publicPages = {
  login: paths.LOGIN,
  set_new_password: paths.SET_NEW_PASSWORD,
  reset_password: paths.RESET_PASSWORD,
  change_password: paths.CHANGE_PASSWORD,
  main: paths.MAIN,
  not_found: paths.NOT_FOUND,
  registration: paths.REGISTRATION,
  confirm_registration: paths.CONFIRM_REGISTRATION,
  all_services: paths.ALL_SERVICES,
};

export const SIZE_IMAGE: imageSizeType = {
  1400: 'a',
  60: 'b',
  124: 'c',
  244: 'd',
  400: 'e',
};

export const LIBRARIES = ['places'];
export const TRUE_STRING = 'true';
export const FALSE_STRING = 'false';

export const SIZE_NA = 'size_na';
export const SIZE_A = 'size_a';
export const SIZE_B = 'size_b';
export const SIZE_C = 'size_c';
export const SIZE_D = 'size_d';
export const SIZE_E = 'size_e';
export const SIZE_F = 'size_f';
export const SIZE_G = 'size_g';

export const COMPANY_SIZE: { [key: string]: string } = {
  [SIZE_NA]: '-',
  [SIZE_A]: '1-10 employees',
  [SIZE_B]: '11-50 employees',
  [SIZE_C]: '51-200 employees',
  [SIZE_D]: '201-500 employees',
  [SIZE_E]: '501-1000  employees',
  [SIZE_F]: '1001-5000 employees',
  [SIZE_G]: '5000+ employees',
};
