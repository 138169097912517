import * as React from 'react';
import { Flex } from 'antd';
import styled from 'styled-components';
import { SearchText } from './SearchText';
import { HomeTextBlockMain } from './HomeTextBlockMain';
import { JoinImage } from './JoinImage';
import { HomeTextBlockSecond } from './HomeTextBlockSecond';
import { ReasonJoin } from './ReasonJoin';
import { Benefits } from './Benefits';
import { PopularCategory } from './PopularCategory';

export const MainTextBlockStyle = styled(Flex)`
  position: relative;
  
  @media (max-width: 768px) {
    
  }
`;

const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url(/back-min.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0;
`;

export const DivStyled = styled.div`
  position: relative;
  `;

export const MainPage: React.FC = () => (
    <>
        <div>
           <BackgroundContainer>
               <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
                    <MainTextBlockStyle vertical>
                        <div style={{ position: 'relative' }}>
                        <HomeTextBlockMain />
                        <SearchText />
                         {/* <GroupCardCategoryService /> */}
                        <PopularCategory />
                        </div>
                    </MainTextBlockStyle>
               </div>
           </BackgroundContainer>
        </div>
        <DivStyled>
            <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
                <MainTextBlockStyle vertical justify={'center'}>
                    <HomeTextBlockSecond />
                    <ReasonJoin />
                </MainTextBlockStyle>
            </div>
        </DivStyled>
        <DivStyled>
            <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
                <MainTextBlockStyle vertical justify={'center'}>
                    <Benefits />
                </MainTextBlockStyle>
            </div>
        </DivStyled>
        <JoinImage />
    </>
);
