import * as React from 'react';
import {
  AdsClick,
  Business,
  Computer,
  Construction,
  Cottage,
  DirectionsCar,
  Diversity2,
  EmojiTransportation,
  Home,
  ImportExport,
  Medication,
  PhotoCamera,
  School,
  SupportAgent,
  Villa,
  Warehouse,
  WorkOutline,
} from '@mui/icons-material';
import { IconServiceType } from '../../common/types';

interface IIconService{
  iconName: IconServiceType,
  size?: number
}

export const IconService: React.FC<IIconService> = ({ iconName, size = 12 }) => {
  switch (iconName) {
    case 'Villa': return <Villa style={{ fontSize: size }} />;
    case 'School': return <School style={{ fontSize: size }} />;
    case 'Diversity2': return <Diversity2 style={{ fontSize: size }} />;
    case 'Cottage': return <Cottage style={{ fontSize: size }} />;
    case 'Construction': return <Construction style={{ fontSize: size }} />;
    case 'Cases': return <WorkOutline style={{ fontSize: size }} />;
    case 'Business': return <Business style={{ fontSize: size }} />;
    case 'ImportExport': return <ImportExport style={{ fontSize: size }} />;
    case 'AdsClick': return <AdsClick style={{ fontSize: size }} />;
    case 'Computer': return <Computer style={{ fontSize: size }} />;
    case 'Medication': return <Medication style={{ fontSize: size }} />;
    case 'Rental': return <EmojiTransportation style={{ fontSize: size }} />;
    case 'Transport': return <DirectionsCar style={{ fontSize: size }} />;
    case 'Photo': return <PhotoCamera style={{ fontSize: size }} />;
    case 'Warehouse': return <Warehouse style={{ fontSize: size }} />;
    case 'SupportAgent': return <SupportAgent style={{ fontSize: size }} />;
    default: return <Home style={{ fontSize: size }} />;
  }
};
