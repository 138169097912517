import { useCallback } from 'react';
import {
  cyan, gold, green, lime, magenta, orange, purple, red, volcano,
} from '@ant-design/colors';
import { useTranslation } from 'react-i18next';
import { CategoryServiceType } from '../../../common/types';

export const S_SOFT = '100';
export const S_WEB = '103';
export const S_LOGISTIC = '101';
export const S_CONSULTING = '102';
export const S_ELECTRONIC = '104';
export const S_STEEL = '105';
export const S_DENTIST = '106';
export const S_MASSAGE = '107';
export const S_MEDICAL = '108';
export const S_IMMIGRATION_COMPANY = '109';
export const S_SCHOOL_PUBLIC = '110';
export const S_TELEPHONE = '111';
export const S_BANK = '112';
export const S_CAR = '113';
export const S_MSP = '114';
export const S_LAWYERS = '115';
export const S_TRANSPORTATION = '116';
export const S_INSURANCE = '117';
export const S_DOCTOR = '118';
export const S_SHOP = '119';
export const S_FOOD = '120';
export const S_COMMUNITY = '121';
export const S_REALTOR = '122';
export const S_JOB_AGENCY = '123';
export const S_REGISTRATION = '124';
export const S_TAX = '125';
export const S_IMMIGRATION_LAWYER = '126';
export const S_SCHOOL_PRIVATE = '127';
export const S_COLLEGE_PRIVATE = '128';
export const S_COLLEGE_PUBLIC = '129';
export const S_CUSTOMS = '131';
export const S_BANK_SERVICES = '132';
export const S_VISA = '164';
export const S_TRIPS = '133';
export const S_MARKETING = '134';
export const S_NETWORKING = '170';
export const S_PLUMBING = '135';
export const S_HVAC = '136';
export const S_ELECTRICIAN = '137';
export const S_LANDSCAPING = '138';
export const S_ROOFING = '139';
export const S_MOVING = '140';
export const S_PAINTING = '141';
export const S_FLOORING = '142';
export const S_LOCKSMITH = '143';
export const S_BUILDING = '144';
export const S_RENOVATION = '145';
export const S_PERMITS = '146';
export const S_MORTGAGE = '147';
export const S_HOUSE_LAWYER = '148';
export const S_HOUSE_INSURANCE = '149';
export const S_HOUSE_INSPECTION = '150';
export const S_UNIVERSITY_PRIVATE = '151';
export const S_UNIVERSITY_PUBLIC = '152';
export const S_SCHOOL_COLLEGE = '163';
export const S_LANGUAGE_SCHOOL = '171';
export const S_WEB_DESIGN = '153';
export const S_AIRPORT_TRANSFER = '154';
export const S_VEHICLE_HIRE = '155';
export const S_VEHICLE_RENT = '165';
export const S_RENT_HOUSE = '156';
export const S_RENT_ROOMS = '157';
export const S_RENT_OFFICE = '158';
export const S_PHOTO_WEDDING = '159';
export const S_PHOTO_DOC = '160';
export const S_VIDEO = '161';
export const S_PHOTO_FAMILY = '162';
export const S_SENIOR = '166';
export const S_SERVICES_FOR_NEW = '167';
export const S_DOCUMENTS = '168';
export const S_GOV = '169';
export const S_ONLINE_COURSES = '172';
export const S_CLEANING = '173';
export const S_HANDYMAN = '174';
export const S_CAREER_COUNSELING = '175';
export const S_TEMP_AGENCIES = '176';
export const S_TRAINING_DEVELOPMENT = '177';

// last index ==== 177

export const useCategory = () => useCallback(() => {
  const { t } = useTranslation();

  const CATEGORY_SERVICES: { [key: string]: string } = {
    [S_TAX]: t('Accounting & Tax'),
    [S_REGISTRATION]: t('Business Registrations'),
    [S_JOB_AGENCY]: t('Job Agency'),
    [S_REALTOR]: t('Realtor'),
    [S_COMMUNITY]: t('Libraries & Communities'),
    [S_FOOD]: t('Food & Bar-Restaurant'),
    [S_SHOP]: t('Shopping'),
    [S_INSURANCE]: t('Insurances'),
    [S_DOCTOR]: t('Family Doctors - Hospital'),
    [S_TRANSPORTATION]: t('Transportations'),
    [S_LAWYERS]: t('Lawyers'),
    [S_MSP]: t('MSP'),
    [S_CAR]: t('Cars & Driving license'),
    [S_BANK]: t('Bank account & cards'),
    [S_TELEPHONE]: t('Telephone, smartphone'),
    [S_IMMIGRATION_COMPANY]: t('Immigration company'),
    [S_IMMIGRATION_LAWYER]: t('Immigration lawyer'),
    [S_SCHOOL_PUBLIC]: t('Public Schools'),
    [S_COLLEGE_PUBLIC]: t('Public Colleges'),
    [S_UNIVERSITY_PUBLIC]: t('Public Universities'),
    [S_SCHOOL_PRIVATE]: t('Private Schools'),
    [S_COLLEGE_PRIVATE]: t('Private Colleges'),
    [S_UNIVERSITY_PRIVATE]: t('Private Universities'),
    [S_SCHOOL_COLLEGE]: t('School and Colleges'),
    [S_SOFT]: t('Software development'),
    [S_WEB]: t('Web-development'),
    [S_LOGISTIC]: t('Logistics'),
    [S_CONSULTING]: t('Consulting'),
    [S_ELECTRONIC]: t('Electronic'),
    [S_STEEL]: t('Steel Sheet Processing - Raw materials'),
    [S_DENTIST]: t('Dentist'),
    [S_MASSAGE]: t('Massage'),
    [S_MEDICAL]: t('Medical'),
    [S_CUSTOMS]: t('Customs'),
    [S_BANK_SERVICES]: t('Banking Services'),
    [S_VISA]: t('Visa'),
    [S_TRIPS]: t('Business Trips, Hotel, Tickets'),
    [S_MARKETING]: t('Marketing'),
    [S_NETWORKING]: t('Networking'),
    [S_PLUMBING]: t('Plumbing'),
    [S_HVAC]: t('HVAC - Heating, Cooling'),
    [S_ELECTRICIAN]: t('Electrician'),
    [S_LANDSCAPING]: t('Landscaping'),
    [S_ROOFING]: t('Roofing'),
    [S_MOVING]: t('Moving'),
    [S_PAINTING]: t('Painting'),
    [S_FLOORING]: t('Flooring'),
    [S_LOCKSMITH]: t('Locksmith'),
    [S_BUILDING]: t('Building'),
    [S_RENOVATION]: t('Renovation'),
    [S_PERMITS]: t('Permits'),
    [S_MORTGAGE]: t('Mortgages'),
    [S_HOUSE_LAWYER]: t('Real Estate Lawyers'),
    [S_HOUSE_INSURANCE]: t('House Insurance'),
    [S_HOUSE_INSPECTION]: t('House Inspection'),
    [S_WEB_DESIGN]: t('Graphic Design'),
    [S_AIRPORT_TRANSFER]: t('Airport Transfer'),
    [S_VEHICLE_HIRE]: t('Vehicle Hire'),
    [S_VEHICLE_RENT]: t('Vehicle Rent'),
    [S_RENT_HOUSE]: t('Rental Housing'),
    [S_RENT_OFFICE]: t('Rent office'),
    [S_RENT_ROOMS]: t('Rent rooms'),
    [S_PHOTO_WEDDING]: t('Wedding Photography'),
    [S_PHOTO_DOC]: t('Passport, Visa and ID'),
    [S_VIDEO]: t('Video'),
    [S_PHOTO_FAMILY]: t('Family Photography'),
    [S_SENIOR]: t('Senior Services'),
    [S_SERVICES_FOR_NEW]: t('Settlement & Immigration Organizations'),
    [S_GOV]: t('Government Organizations and Resources'),
    [S_DOCUMENTS]: t('Documents'),
    [S_LANGUAGE_SCHOOL]: t('Language School'),
    [S_ONLINE_COURSES]: t('Online Courses'),
    [S_CLEANING]: t('Cleaning Services'),
    [S_HANDYMAN]: t('Handyman Services'),
    [S_CAREER_COUNSELING]: t('Career Counseling'),
    [S_TEMP_AGENCIES]: t('Temp Agencies'),
    [S_TRAINING_DEVELOPMENT]: t('Training & Development'),
  };

  const GROUP_CATEGORY_SERVICES: { [key: string]: CategoryServiceType } = {
    NP: {
      caption: 'Government and Not-Profit Organisations',
      shortCaption: 'NP',
      category: [S_SERVICES_FOR_NEW, S_GOV],
      color: gold[3],
      icon: 'SupportAgent',
    },
    IS: {
      caption: 'Immigration Services',
      shortCaption: 'IS',
      category: [S_IMMIGRATION_COMPANY, S_IMMIGRATION_LAWYER],
      color: volcano[3],
      icon: 'Villa',
    },
    SS: {
      caption: t('Education Services'),
      shortCaption: 'SS',
      category: [S_SCHOOL_COLLEGE, S_LANGUAGE_SCHOOL, S_ONLINE_COURSES],
      color: lime[3],
      icon: 'School',
    },
    LS: {
      caption: t('Life Services'),
      shortCaption: 'LS',
      category: [S_MSP, S_DOCUMENTS, S_TELEPHONE, S_BANK, S_CAR, S_LAWYERS, S_TRANSPORTATION, S_INSURANCE, S_DOCTOR, S_SHOP, S_COMMUNITY, S_FOOD, S_SENIOR],
      color: orange[3],
      icon: 'Diversity2',
    },
    HM: {
      caption: t('Real Estate & Mortgages'),
      shortCaption: 'HM',
      category: [S_RENT_HOUSE, S_REALTOR, S_MORTGAGE, S_HOUSE_LAWYER, S_HOUSE_INSPECTION, S_HOUSE_INSURANCE, S_RENT_ROOMS, S_RENT_OFFICE],
      color: cyan[3],
      icon: 'Cottage',
    },
    // RE: {
    //   caption: t('Rental Property'),
    //   shortCaption: 'Re',
    //   category: [],
    //   color: green[3],
    //   icon: 'Rental',
    // },
    HS: {
      caption: t('Housing Services'),
      shortCaption: 'HS',
      category: [S_PLUMBING, S_HVAC, S_ELECTRICIAN, S_LANDSCAPING, S_ROOFING, S_MOVING, S_PAINTING, S_FLOORING, S_LOCKSMITH, S_BUILDING, S_RENOVATION, S_PERMITS, S_CLEANING, S_HANDYMAN],
      color: gold[3],
      icon: 'Construction',
    },
    JC: {
      caption: t('Jobs & Career'),
      shortCaption: 'JC',
      category: [S_JOB_AGENCY, S_TEMP_AGENCIES, S_CAREER_COUNSELING, S_TRAINING_DEVELOPMENT],
      color: red[3],
      icon: 'Cases',
    },
    SB: {
      caption: t('Small business'),
      shortCaption: 'SB',
      category: [S_REGISTRATION, S_TAX, S_BANK_SERVICES, S_VISA, S_TRIPS],
      color: magenta[3],
      icon: 'Business',
    },
    IE: {
      caption: t('Import/Export'),
      shortCaption: 'IE',
      category: [S_CUSTOMS, S_LOGISTIC],
      color: green[3],
      icon: 'ImportExport',
    },
    TP: {
      caption: t('Trade Promotions'),
      shortCaption: 'TP',
      category: [S_MARKETING],
      color: gold[3],
      icon: 'AdsClick',
    },
    IT: {
      caption: t('Information Technology'),
      shortCaption: 'IT',
      category: [S_SOFT, S_WEB, S_WEB_DESIGN],
      color: purple[3],
      icon: 'Computer',
    },
    ME: {
      caption: t('Medical'),
      shortCaption: 'Me',
      category: [S_DENTIST, S_MASSAGE, S_MSP, S_DOCTOR],
      color: green[3],
      icon: 'Medication',
    },
    TR: {
      caption: t('Transport'),
      shortCaption: 'Tr',
      category: [S_AIRPORT_TRANSFER, S_VEHICLE_HIRE, S_VEHICLE_RENT],
      color: orange[3],
      icon: 'Transport',
    },
    PHV: {
      caption: t('Photography and Videography'),
      shortCaption: 'PhV',
      category: [S_PHOTO_WEDDING, S_PHOTO_FAMILY, S_PHOTO_DOC, S_VIDEO],
      color: volcano[3],
      icon: 'Photo',
    },
    PP: {
      caption: t('Parts, processing, assembly'),
      shortCaption: 'PhV',
      category: [S_STEEL],
      color: lime[3],
      icon: 'Warehouse',
    },
  };

  const getGroupCategoryByCategory = (category: string) => {
    for (const groupKey of Object.keys(GROUP_CATEGORY_SERVICES)) {
      if (GROUP_CATEGORY_SERVICES[groupKey].category.includes(category)) return groupKey;
    }
    return undefined;
  };

  return { CATEGORY_SERVICES, GROUP_CATEGORY_SERVICES, getGroupCategoryByCategory };
}, [])();
