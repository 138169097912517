import * as React from 'react';
import { Col, Flex, Row } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ButtonRegistration } from './Buttons/ButtonRegistration';
import { useResizeWidthWindow } from '../hooks/useResizeWidthWindow';

export const SpanStyled = styled.span`
  font-size: 3rem;
  color: white;
  font-weight: 800;
  padding-right: 20px;
  display: block;
  
  @media (max-width: 768px) {
    font-size: 2rem;
    padding-right: 0;
    padding-bottom: 10px;
`;

export const RowStyled = styled(Row)`
  padding: 20px;
`;

export const JoinImage: React.FC = () => {
  const { t } = useTranslation();

  return (
      <div>
          <Flex justify={'center'} style={{ margin: '20px 0', position: 'relative', width: '100%' }} vertical align={'center'}>
              <div style={{
                width: '100%',
                height: '100%',
                position: 'relative',
                backgroundImage: 'url(/main_join.jpg)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                padding: '100px 0',
              }}>
                  <div style={{
                    bottom: 0,
                    height: '100%',
                    left: 0,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    width: '100%',
                    background: 'linear-gradient(180deg,rgba(22,28,45,.5) 0,rgba(22,28,45,.6) 25%,rgba(22,28,45,.7) 50%,rgba(22,28,45,.8))',
                  }}
                  >
                  </div>
                  <div style={{ maxWidth: '1200px', margin: '0 auto', textAlign: useResizeWidthWindow() < 768 ? 'center' : 'justify' }}>
                      <RowStyled align={'middle'} >
                          <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                            <SpanStyled>{t('Join our community today!')}</SpanStyled>
                          </Col>
                          <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                            <ButtonRegistration size={'large'} withIcon={false}/>
                          </Col>
                      </RowStyled>
                </div>
              </div>
          </Flex>
      </div>
  );
};
