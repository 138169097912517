import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  AutoComplete, Button, Flex, Input, Space, Typography,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router';
import { LocationOn, Search } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppDispatch, RootState } from '../store';
import { setSearchParam, setterApp } from '../store/slices/appSlice';
import { API, paths } from '../common';
import { useResizeWidthWindow } from '../hooks/useResizeWidthWindow';

const { Text } = Typography;

const searchHeightFull = '70px';
const searchHeightSmall = '50px';

const FlexStyled = styled(Flex)`
    //padding: 40px 40px 10px 40px;
    padding: 40px;
    text-align: left;
    margin: 0 auto;
    width: 100%;

  && .search_item{
    height: ${searchHeightFull};
    font-size: 20px;
  }
  
  && .search_button{
    height: ${searchHeightFull};
  }
  
  @media (max-width: 768px) {
    padding: 20px 20px 5px 20px;

    && .search_item{
      height: ${searchHeightSmall};
      font-size: 16px;
    }

    && .search_button{
      height: ${searchHeightSmall};
    }
  }
  `;

const renderTitle = (title: string) => (
    <span>
    {title}
  </span>
);

const getHighLightText = (title: string, highLightText: string) => {
  const arrayTitle = title
    .split(new RegExp(`(${highLightText})`, 'i'))
    .map((part, index) => (index % 2 === 0 ? part : `${part}`));

  return <p>{arrayTitle.map((text) => {
    if (text.toLowerCase() === highLightText.toLowerCase()) {
      return <Text key={uuidv4()} mark>{text}</Text>;
    }

    return text;
  })}</p>;
};

const renderItem = (title: string, highLightText: string) => ({
  value: `${title}#${uuidv4()}#`,
  label: (
      <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
      >
        {getHighLightText(title, highLightText)}
        <span>
      </span>
      </div>
  ),
});

type OptionsAutocompleteType = { label: string, options: string[] };
type OptionsAutocompleteRenderType = { label: React.JSX.Element, options: {
  value: string,
  label: React.JSX.Element
}[] };

let timerId: any;

export const SearchText: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const searchLocation = useSelector<RootState, string | undefined>((state) => state.app.searchLocation);
  const searchText = useSelector<RootState, string | undefined>((state) => state.app.searchText);
  const [options, setOptions] = useState<OptionsAutocompleteRenderType[]>([]);
  const [optionsLocal, setOptionsLocal] = useState<OptionsAutocompleteRenderType[]>([]);
  const history = useNavigate();
  const location = useLocation();
  const [isSaving, setIsSaving] = useState(0);
  const [isGoingToSearchPage, setIsGoingToSearchPage] = useState(0);

  useEffect(() => {
    dispatch(setSearchParam(`search=${searchText}&searchLocation=${searchLocation}`));
  }, [isSaving]);

  useEffect(() => {
    if (!searchText) {
      setIsSaving((oldValue) => oldValue + 1);
    }
  }, [searchText]);

  useEffect(() => {
    if (isGoingToSearchPage > 0) {
      history(paths.SEARCH);
    }
  }, [isGoingToSearchPage]);

  useEffect(() => {
    if (!searchLocation) {
      setIsSaving((oldValue) => oldValue + 1);
    }
  }, [searchLocation]);

  const onChangeSearch = (path: string) => async (value: string) => {
    const newValue = value?.replace(/#(.*?)#/g, '');
    try {
      const isSearch = path === paths.AUTOCOMPLETE_SEARCH;
      const newSearchParam = isSearch ? { searchText: newValue } : { searchLocation: newValue };
      dispatch(setterApp(newSearchParam));

      clearTimeout(timerId);
      timerId = setTimeout(async () => {
        const response = (await axios.get<OptionsAutocompleteType[]>(`${API.URL_REST_API}${path}?search=${newValue}`)).data;
        const newOptions = response.map((unit) => ({
          label: renderTitle(unit.label),
          key: uuidv4() as string,
          options: unit.options.map((option) => renderItem(option, newValue)),
        }));

        if (isSearch) {
          setOptions(newOptions);
        } else {
          setOptionsLocal(newOptions);
        }
      }, 200);
    } catch (e) {
      console.log(e);
    }
  };

  const onClickSearch = useCallback(() => {
    setIsSaving((oldValue) => oldValue + 1);
    if (location.pathname === paths.MAIN) {
      setIsGoingToSearchPage((oldValue) => oldValue + 1);
    }
  },
  [searchText, searchLocation]);

  return (
      <FlexStyled vertical>
        <Space.Compact size="large" direction={useResizeWidthWindow() < 500 ? 'vertical' : 'horizontal'}>
          <AutoComplete
              allowClear
              popupClassName="certain-category-search-dropdown"
              style={{ width: '100%', height: '100%' }}
              options={options}
              // size="large"
              value={searchText}
              onChange={onChangeSearch(paths.AUTOCOMPLETE_SEARCH)}
              onSelect={onClickSearch}
          >
            <Input placeholder={t('Search by name, produce or tag')} prefix={<Search />} className={'search_item'}
                   onKeyPress={(e) => {
                     if (e.key === 'Enter') {
                       onClickSearch();
                     }
                   }}
            />
          </AutoComplete>
          <AutoComplete
              allowClear
              popupClassName="certain-category-search-dropdown"
              style={{ width: '100%', height: '100%' }}
              options={optionsLocal}
              value={searchLocation}
              onChange={onChangeSearch(paths.AUTOCOMPLETE_SEARCH_LOCAL)}
              onSelect={onClickSearch}
              >
            <Input placeholder={t('Address, city or postal code')} prefix={<LocationOn />} className={'search_item'}
                   onKeyPress={(e) => {
                     if (e.key === 'Enter') {
                       onClickSearch();
                     }
                   }}
            />
          </AutoComplete>
          <div style={{ width: useResizeWidthWindow() < 500 ? '100%' : 'inherit' }}>
          <Link to={paths.SEARCH} >
            <Button type="primary" onClick={onClickSearch}
                    className={'search_button'}
                    aria-label={'Search'}
                    style={{ width: useResizeWidthWindow() < 500 ? '100%' : 'inherit' }}>
              Search
            </Button>
          </Link>
          </div>
        </Space.Compact>
    </FlexStyled>
  );
};
