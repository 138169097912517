import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { Button, Flex } from 'antd';
import { Card, CardContent } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';
import { paths } from '../../common';
import { colors } from '../../common/constStyles';

export const CardStyled = styled(Card)`
  box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: none;
  position: relative;
  padding: 30px;
  border-color: ${colors.colorGreyDark};

  .icon_container {
    //align-items: center;
    //display: flex;
    //height: 60px;
    //justify-content: center;
    //width: 60px;
    transition: all .5s ease;
    border-radius: 6px !important;
    background-color: #3274f0;
  }
  
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

interface ICardStyled {
  icon: IconProp,
  textFirst: string,
  textSecond: string,
  textThird: ReactElement<any, any>,
  regAs: string
}

export const CardReasonJoin: React.FC<ICardStyled> = ({
  icon, textFirst, textThird, textSecond, regAs,
}) => {
  const onClick = useCallback(() => {
  }, []);

  return (
        <CardStyled variant="outlined" style={{ height: '100%' }}>
            <CardContent>
                <Flex vertical justify={'center'}>
                    <Link to={regAs === 'user' ? paths.REGISTRATION_USER : paths.REGISTRATION_PROVIDER} onClick={onClick}>
                        <Button aria-label={'reg'} type={'primary'} disabled={regAs === 'user'} style={{ width: '100%', height: '60px' }}>
                            <FontAwesomeIcon icon={icon} color={'white'} size="lg"/>
                            <span style={{ fontSize: '1.3em', marginLeft: '10px', fontWeight: 600 }}>Join as {regAs === 'user' ? 'Service Seeker' : 'Service Provider'}</span>
                        </Button>
                    </Link>
                    <p style={{
                      fontSize: '20px', fontWeight: 'bold', padding: '20px 0', textTransform: 'uppercase',
                    }}>{textFirst}</p>
                    <p style={{ fontSize: '16px', paddingBottom: '20px' }}>{textSecond}</p>
                    <div className={'text_second justify'}>
                        {textThird}
                    </div>
                </Flex>
            </CardContent>
        </CardStyled>
  );
};
